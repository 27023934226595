// src/App.js

import React from "react";
// import Map from "./components/Map";
import R3fMap from "./components/R3fMap";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div style={{ position: "relative", height: "100vh", width: "100vw" }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<R3fMap />} />
        </Routes>
      </BrowserRouter>
      {/* <Map/> */}
    </div>
  );
}

export default App;
