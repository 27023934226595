export async function fetchRequest(
  url,
  method = "GET",
  body = null,
  headers = {}
) {
  try {
    headers = {
      "Content-Type": "application/json",
      ...headers,
    };
    const options = {
      method,
      headers,
      body: body ? JSON.stringify(body) : null,
    };

    const response = await fetch(url, options);

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
}

export const getBuildingAttribute = (buildings, buildingName, key) => {
  const lowerCaseKey = key.toLowerCase();
  const building = buildings.find((b) => b.name === buildingName);
  if (!building) return null;

  const attribute = building.attributes.find(
    (attr) => attr.key.toLowerCase() === lowerCaseKey
  )?.value;
  return attribute ? attribute : null;
};

export const getBuildingName = (buildings, selectedPart) => {
  const buildingName = buildings?.find((b) => b.name === selectedPart)?.name;
  return buildingName;
};

export const getRoomAttributes = (room, key) => {
  if (!room?.attributes) {
    return null;
  }
  const lowerCaseKey = key.toLowerCase();
  const attribute = room?.attributes?.find(
    (attr) => attr.key.toLowerCase() === lowerCaseKey
  );
  if (!attribute?.key || !attribute?.value) {
    return null;
  }
  const { key: attributeKey, value } = attribute;
  return { key: attributeKey, value };
};

export const getBuildingAttributeOnKey = (attributes, key) => {
  const lowerCaseKey = key.toLowerCase();
  const buildingAttr = attributes?.find(
    (attr) => attr.key.toLowerCase() === lowerCaseKey
  )?.value;
  return buildingAttr;
};
