import React, { useState } from "react";
import { Box, IconButton, Image, Flex, AspectRatio } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const ImageCarousel = ({ urls, type }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % urls.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? urls.length - 1 : prevIndex - 1
    );
  };

  const showSlideButtons = urls?.length > 1;

  return (
    <Box
      width="90%"
      margin="0 auto"
      overflow="hidden"
      display="flex"
      justifyContent="center"
    >
      <Flex
        transform={`translateX(-${currentIndex * 100}%)`}
        transition="transform 0.5s ease-in-out"
        width={`${urls.length * 100}%`}
      >
        {urls.map((url, index) => (
          <Box
            key={index}
            flex="0 0 100%"
            display="flex"
            justifyContent="center"
          >
            {type === "image" ? (
              <Image
                src={url}
                alt={`Slide ${index + 1}`}
                width="100%"
                height="85vh"
                objectFit="cover"
                borderRadius="md"
              />
            ) : (
              <Box
                width="100%"
                h="88vh"
                display="flex"
                justifyContent="center"
                overflow="hidden"
              >
                <AspectRatio width="100%" ratio={1}>
                  <iframe title={url} src={url} allowFullScreen />
                </AspectRatio>
              </Box>
            )}
          </Box>
        ))}
      </Flex>
      {showSlideButtons && (
        <IconButton
          icon={<ChevronLeftIcon />}
          position="absolute"
          top="50%"
          left="10px"
          transform="translateY(-50%)"
          onClick={handlePrev}
          aria-label="Previous Slide"
        />
      )}

      {showSlideButtons && (
        <IconButton
          icon={<ChevronRightIcon />}
          position="absolute"
          top="50%"
          right="10px"
          transform="translateY(-50%)"
          onClick={handleNext}
          aria-label="Next Slide"
        />
      )}
    </Box>
  );
};

export default ImageCarousel;
