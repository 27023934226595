import React, { useRef, useState, useEffect, useMemo } from "react";
import Map from "react-map-gl";
import { Canvas } from "react-three-map";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import { Box, Text, VStack } from "@chakra-ui/react";
import { Html, Environment } from "@react-three/drei";
import { ChakraProvider } from "@chakra-ui/react";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { HStack, Grid, GridItem, Button } from "@chakra-ui/react";
import { easeCubic } from "d3-ease";
import Sidebar from "./Sidebar";
import mapboxgl from "mapbox-gl";
import { fetchRequest, getBuildingAttribute } from "../utils";

const ZOOM_LEVEL = 18;
const INITIAL_VIEW_STATE = {
  longitude: -72.28949345934565,
  latitude: 43.70438686950007,
  zoom: 17.8,
  pitch: 70.9,
  bearing: -10.5,
};

const initialBuildingData = {
  buildings: [
    {
      id: "2d4ac03e-6858-4f6d-8b4a-79fb50002864",
      name: "109-111 Rosedale Circle",
      address: null,
      model: "/building-exports/bakerberrylibrary3.glb",
      attributes: [
        { key: "latitude", value: "43.730336869500036" },
        { key: "longitude", value: "-72.270673459345616" },
        { key: "occupancy", value: "528" },
        { key: "roomTypes", value: "Singles, Doubles, Triples" },
        { key: "floorLayout", value: "Single & Mixed Gender Floors" },
        { key: "academicLevel", value: "1st Year, Returning Students" },
        { key: "bathroomType", value: "Communal, Gendered" },
        { key: "kitchen", value: "One on ground floor" },
        { key: "storage", value: "Indoor Bike Storage" },
      ],
      features: [],
    },
  ],
};
//Query Params using window object
// const getQueryParams = () => {
//   const params = new URLSearchParams(window.location.search);
//   const orgId = parseFloat(params.get("orgId"));
//   return orgId;
// };

// console.log("1111", getQueryParams());

const convertLatLngToVector3 = (longitude, latitude) => {
  const mapCenter = mapboxgl.MercatorCoordinate.fromLngLat([
    INITIAL_VIEW_STATE.longitude,
    INITIAL_VIEW_STATE.latitude,
  ]);
  const position = mapboxgl.MercatorCoordinate.fromLngLat([
    longitude,
    latitude,
  ]);

  const x = (position.x - mapCenter.x) * 1000000;
  const z = (position.y - mapCenter.y) * 1000000; // Swap Y to Z
  const y = 0; // Altitude remains the same

  return new THREE.Vector3(x, y, z);
};

function getCurrentSubdomain() {
  let hostname = window.location.hostname;
  hostname = hostname.replace(/^https?:\/\//, "");

  const parts = hostname.split(".");
  if (
    hostname === "localhost" ||
    process.env.REACT_APP_ACTIVE_ENV === "local"
  ) {
    return "wake-forest-staging";
  }

  if (parts.length > 2) {
    return parts.slice(0, parts.length - 2).join(".");
  }

  return null;
}

const ArrowButton = ({ onClick, icon, label }) => (
  <Button size="sm" onClick={onClick} aria-label={label}>
    {icon}
  </Button>
);

const DebugTool = ({
  buildingPositions,
  adjustPosition,
  selectedBuildingName,
}) => {
  const formatCoordinate = (value) => {
    if (typeof value === "number") {
      return value.toFixed(15);
    } else if (value !== undefined) {
      return Number(value).toFixed(15);
    }
    return "N/A";
  };

  const selectedPosition = selectedBuildingName
    ? buildingPositions[selectedBuildingName]
    : null;

  return (
    <Box
      position="absolute"
      bottom="20px"
      right="20px"
      bg="white"
      p={4}
      borderRadius="md"
      boxShadow="lg"
    >
      <VStack align="start" spacing={2}>
        <Text>Current Model Position:</Text>
        <Text>
          Latitude:
          {selectedPosition
            ? formatCoordinate(selectedPosition.latitude)
            : "N/A"}
        </Text>
        <Text>
          Longitude:
          {selectedPosition
            ? formatCoordinate(selectedPosition.longitude)
            : "N/A"}
        </Text>
        <Text>Adjust Position:</Text>
        <HStack justify="space-between" align="center">
          {/* Fine adjustment */}
          <VStack>
            <Text fontSize="md">Fine (0.00001)</Text>
            <Grid templateColumns="repeat(3, 1fr)" gap={1}>
              <GridItem colStart={2}>
                <ArrowButton
                  onClick={() => adjustPosition("latitude", 0.0001)}
                  icon={<ChevronUpIcon />}
                  label="N"
                />
              </GridItem>
              <GridItem colStart={1} rowStart={2}>
                <ArrowButton
                  onClick={() => adjustPosition("longitude", -0.0001)}
                  icon={<ChevronLeftIcon />}
                  label="W"
                />
              </GridItem>
              <GridItem colStart={3} rowStart={2}>
                <ArrowButton
                  onClick={() => adjustPosition("longitude", 0.0001)}
                  icon={<ChevronRightIcon />}
                  label="E"
                />
              </GridItem>
              <GridItem colStart={2} rowStart={3}>
                <ArrowButton
                  onClick={() => adjustPosition("latitude", -0.0001)}
                  icon={<ChevronDownIcon />}
                  label="S"
                />
              </GridItem>
            </Grid>
          </VStack>
          {/* Coarse adjustment */}
          <VStack>
            <Text fontSize="md">Coarse (0.0001)</Text>
            <Grid templateColumns="repeat(3, 1fr)" gap={1}>
              <GridItem colStart={2}>
                <ArrowButton
                  onClick={() => adjustPosition("latitude", 0.001)}
                  icon={<ChevronUpIcon />}
                  label="N"
                />
              </GridItem>
              <GridItem colStart={1} rowStart={2}>
                <ArrowButton
                  onClick={() => adjustPosition("longitude", -0.001)}
                  icon={<ChevronLeftIcon />}
                  label="W"
                />
              </GridItem>
              <GridItem colStart={3} rowStart={2}>
                <ArrowButton
                  onClick={() => adjustPosition("longitude", 0.001)}
                  icon={<ChevronRightIcon />}
                  label="E"
                />
              </GridItem>
              <GridItem colStart={2} rowStart={3}>
                <ArrowButton
                  onClick={() => adjustPosition("latitude", -0.001)}
                  icon={<ChevronDownIcon />}
                  label="S"
                />
              </GridItem>
            </Grid>
          </VStack>
        </HStack>
      </VStack>
    </Box>
  );
};

const BuildingModel = ({
  building,
  model,
  handleBuildingClick,
  setSelectedPart,
  position,
  selectedPart,
  buildingFilters,
}) => {
  const modelRef = useRef();
  const buildingPosition = useRef(new THREE.Vector3());

  const { adjustedModel, height } = useMemo(() => {
    if (model) {
      const scale = building.scale || 1;

      // Extract the first child
      const buildingMesh = model.scene.children[0];
      const buildingClone = buildingMesh.clone();

      // Center the model
      const bbox = new THREE.Box3().setFromObject(buildingClone);
      const center = bbox.getCenter(new THREE.Vector3());
      buildingClone.position.sub(center);

      // Find the actual lowest point (minY)
      let minY = Infinity;

      buildingClone.traverse((child) => {
        if (child.isMesh) {
          child.geometry.computeBoundingBox();
          const childBbox = child.geometry.boundingBox;
          minY = Math.min(minY, childBbox.min.y);
        }
      });

      // Adjust the building's position so its base is at y=0
      buildingClone.position.y -= minY;

      // Apply scale
      buildingClone.scale.set(scale, scale, scale);

      // Recompute the bounding box after adjustments
      const adjustedBbox = new THREE.Box3().setFromObject(buildingClone);
      const buildingHeight = adjustedBbox.max.y - adjustedBbox.min.y;

      // Ensure materials are double-sided
      buildingClone.traverse((child) => {
        if (child.isMesh) {
          child.material.side = THREE.DoubleSide;
        }
      });

      // Create and store the adjusted model
      const adjustedModel = new THREE.Object3D();
      adjustedModel.add(buildingClone);

      return { adjustedModel, height: buildingHeight * scale };
    }
    return { adjustedModel: null, height: 0 };
  }, [building, model]);

  // Update the building's world position after it's been positioned in the scene
  useEffect(() => {
    if (modelRef.current) {
      modelRef.current.updateMatrixWorld();
      modelRef.current.getWorldPosition(buildingPosition.current);
    }
  }, [position]); // Re-run whenever position changes

  if (!adjustedModel) return null;

  const handleClick = (event) => {
    event.stopPropagation();
    handleBuildingClick(building, buildingPosition.current);
    setSelectedPart(building.name);
  };

  const isSelected =
    selectedPart === building.name || buildingFilters.includes(building?.type);

  return (
    <>
      <primitive
        ref={modelRef}
        object={adjustedModel}
        position={position}
        onClick={handleClick}
        onPointerOver={() => {
          document.body.style.cursor = "pointer";
        }}
        onPointerOut={() => {
          document.body.style.cursor = "default";
        }}
      />
      <Html
        position={[position.x, position.y + height + 2, position.z]}
        style={{
          pointerEvents: "auto",
          cursor: "pointer",
          transform: "translate3d(-50%, -100%, 0)",
        }}
      >
        <VStack
          alignItems="center"
          onClick={(e) => {
            e.stopPropagation();
            handleBuildingClick(building, buildingPosition.current);
            setSelectedPart(building.name);
          }}
          spacing={2}
          style={{
            textAlign: "center",
            fontSize: "1.25em",
            lineHeight: "1.5em",
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0)",
            padding: "10px",
            fontWeight: "bold",
            borderRadius: "5px",
            width: "250px",
            textShadow: isSelected
              ? "1px 1px 0px #389E0D, -1px -1px 0px #389E0D, 1px -1px 0px #389E0D, -1px 1px 0px #389E0D"
              : "1px 1px 0px gray, -1px -1px 0px gray, 1px -1px 0px gray, -1px 1px 0px gray",
          }}
        >
          <img
            src="/placemarker.png"
            alt="Building"
            width="30px"
            style={{ margin: "auto" }}
          />
          <Text>{building.name}</Text>
        </VStack>
      </Html>
    </>
  );
};

const CanvasContent = ({
  buildings,
  models,
  handleBuildingClick,
  setSelectedPart,
  selectedPart,
  buildingPositions,
  buildingFilters,
}) => {
  return (
    <>
      <Environment preset="forest" />
      <ambientLight intensity={0.5} />
      <directionalLight
        position={[50, 50, 50]}
        intensity={1}
        color="#ffffff"
        castShadow={true}
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
        shadow-camera-far={200}
        shadow-camera-near={0.5}
        shadow-camera-left={-50}
        shadow-camera-right={50}
        shadow-camera-top={50}
        shadow-camera-bottom={-50}
      />
      {buildings.map((building, index) => {
        let position;
        if (
          buildingPositions?.[building.name]?.longitude &&
          buildingPositions?.[building.name]?.latitude
        ) {
          position = convertLatLngToVector3(
            buildingPositions?.[building.name]?.longitude,
            buildingPositions?.[building.name]?.latitude
          );

          return (
            <BuildingModel
              key={building.id}
              building={building}
              model={models[index]}
              handleBuildingClick={handleBuildingClick}
              setSelectedPart={setSelectedPart}
              position={position}
              selectedPart={selectedPart}
              buildingFilters={buildingFilters}
            />
          );
        }
        return null;
      })}
    </>
  );
};

const getBuildingPositions = (buildings) => {
  if (!buildings) return {};

  const positions = {};
  buildings.forEach((building) => {
    const latitude = getBuildingAttribute(
      buildings,
      building?.name,
      "latitude"
    );
    const longitude = getBuildingAttribute(
      buildings,
      building?.name,
      "longitude"
    );
    positions[building.name] = {
      latitude: Number(latitude),
      longitude: Number(longitude),
    };
  });

  return positions;
};

const R3fMap = () => {
  const mapRef = useRef(null);
  const [mapStyle, setMapStyle] = useState(
    "mapbox://styles/zakroomie/cm1f8c40400sh01nw7ydv2hwa"
  );
  const [buildings, setBuildings] = useState(initialBuildingData.buildings);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedPart, setSelectedPart] = useState(null);
  const [showDebugTool, setShowDebugTool] = useState(false);
  const [buildingPositions, setBuildingPositions] = useState(
    getBuildingPositions(initialBuildingData?.buildings)
  );
  const [buildingFilters] = useState([]);
  const [universityDetails, setUniversityDetails] = useState(null);

  //TODO : Future Implementation
  // const handleBuildingFilters = useCallback(
  //   (selectedBuildingFilter) => {
  //     setBuildingFilters((prevBuildingFilters) => {
  //       const updatedBuildingFilters = [...prevBuildingFilters];

  //       if (updatedBuildingFilters.includes(selectedBuildingFilter)) {
  //         return updatedBuildingFilters.filter(
  //           (buildingType) => buildingType !== selectedBuildingFilter
  //         );
  //       } else {
  //         return [...updatedBuildingFilters, selectedBuildingFilter];
  //       }
  //     });
  //   },
  //   [setBuildingFilters]
  // );

  useEffect(() => {
    async function fetchBuildings() {
      try {
        const subdomain = getCurrentSubdomain();
        const endpoint = `/university-map-details?origin=${subdomain}`;
        const data = await fetchRequest(
          `${process.env.REACT_APP_API_BASE_URL}${endpoint}`,
          "GET"
        );

        let buildings = data?.buildings?.filter(
          (building) => building?.attributes?.length > 0 && building?.model
        );

        // buildings = buildings.map((building, index) =>
        //   index < 3
        //     ? { ...building, type: "Residential" }
        //     : { ...building, type: "Dining" }
        // );

        setBuildings(buildings);
        setUniversityDetails({
          name: data?.name,
          friendlyName: data?.friendlyName,
          logo: data?.logo,
        });
        const buildingPositionsInitial = getBuildingPositions(buildings);
        setBuildingPositions(buildingPositionsInitial);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchBuildings();
  }, []);

  useEffect(() => {
    if (showDebugTool) {
      setMapStyle("mapbox://styles/mapbox/standard");
    } else {
      setMapStyle("mapbox://styles/zakroomie/cm1f8c40400sh01nw7ydv2hwa");
    }
  }, [showDebugTool]);

  const models = useLoader(
    GLTFLoader,
    buildings?.map((building) => building?.model)
  );

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "p" || event.key === "P") {
        setShowDebugTool((prev) => !prev);
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const adjustPosition = (coordinate, adjustment) => {
    setBuildingPositions((prevPositions) => {
      const buildingName = selectedPart;
      if (!buildingName) return prevPositions;

      const currentValue = Number(prevPositions[buildingName][coordinate]);
      const adjustmentValue = Number(adjustment);

      if (isNaN(currentValue) || isNaN(adjustmentValue)) {
        console.error("Invalid value encountered:", {
          coordinate,
          currentValue,
          adjustment,
        });
        return prevPositions;
      }

      const newValue = parseFloat((currentValue + adjustmentValue).toFixed(15));

      return {
        ...prevPositions,
        [buildingName]: {
          ...prevPositions[buildingName],
          [coordinate]: newValue,
        },
      };
    });
  };

  const handleRoomDetails = (room) => {
    if (room) {
      setSelectedRoom(room);
      return;
    }
    setSelectedRoom(null);
  };

  const convertVector3ToLatLng = (vector3) => {
    const mapCenter = mapboxgl.MercatorCoordinate.fromLngLat([
      INITIAL_VIEW_STATE.longitude,
      INITIAL_VIEW_STATE.latitude,
    ]);

    const scale = 28000000;

    const positionX = vector3.x / scale + mapCenter.x;
    const positionY = vector3.z / scale + mapCenter.y;

    const mercatorCoordinate = new mapboxgl.MercatorCoordinate(
      positionX,
      positionY
    );

    const lngLat = mercatorCoordinate.toLngLat();
    return [lngLat.lng, lngLat.lat];
  };

  const flyToLocation = (longitude, latitude) => {
    const map = mapRef.current?.getMap();
    if (map && longitude && latitude) {
      map.flyTo({
        center: [longitude, latitude],
        zoom: ZOOM_LEVEL,
        speed: 0.8,
        curve: 1.42,
        easing: easeCubic,
        essential: true,
      });
    }
  };

  const handleBuildingClick = (building, modelPosition) => {
    setSelectedPart(building.name);
    const attributes = building?.attributes;
    if (attributes?.length) {
      const position = convertLatLngToVector3(
        buildingPositions?.[building.name]?.longitude,
        buildingPositions?.[building.name]?.latitude
      );
      const [long, lat] = convertVector3ToLatLng(position);
      flyToLocation(long, lat);
    }
    if (modelPosition) {
      // Convert Three.js position to geographic coordinates
      const [longitude, latitude] = convertVector3ToLatLng(modelPosition);
      console.log("Flying to location", longitude, latitude);
      flyToLocation(longitude, latitude);
    }
  };

  const handleBackToList = () => {
    setSelectedPart(null);
    const map = mapRef.current?.getMap();
    if (map) {
      map.flyTo({
        ...INITIAL_VIEW_STATE,
        speed: 0.8,
        curve: 1.42,
        easing: easeCubic,
        essential: true,
      });
    }
  };

  const dartmouthBounds = [
    [-72.298, 43.703],
    [-72.281, 43.712],
  ];
  //TODO : Future Implementation
  // function handleBuildingTypeFilterTextColor(filter) {
  //   const isSelected = buildingFilters.includes(filter);
  //   if (!isSelected) {
  //     return "#272C3A";
  //   }
  //   switch (filter) {
  //     case "Residential":
  //       return "#0783EB";
  //     case "Academic":
  //       return "#079E9E";
  //     case "Dining":
  //       return "#D48806";
  //     case "Athletic":
  //       return "#389E0D";
  //     case "Admin":
  //       return "#1D39C4";
  //     default:
  //       return "#272C3A";
  //   }
  // }

  return (
    <ChakraProvider>
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <Map
          ref={mapRef}
          antialias
          initialViewState={INITIAL_VIEW_STATE}
          style={{ width: "100%", height: "100%" }}
          mapStyle={mapStyle}
          mapboxAccessToken="pk.eyJ1IjoiemFrcm9vbWllIiwiYSI6ImNsenZsZjV6dTA4cHUycXByaTVycnJkMjcifQ.b5eA5N9VYdJhRFlcT5fytw"
          maxBounds={dartmouthBounds}
        >
          <Canvas
            latitude={INITIAL_VIEW_STATE.latitude}
            longitude={INITIAL_VIEW_STATE.longitude}
          >
            <CanvasContent
              buildings={buildings}
              models={models}
              handleBuildingClick={handleBuildingClick}
              setSelectedPart={setSelectedPart}
              selectedPart={selectedPart}
              buildingPositions={buildingPositions}
              buildingFilters={buildingFilters}
            />
          </Canvas>
        </Map>
        {/* <BuildingFilterBar
          handleBuildingFilters={handleBuildingFilters}
          handleBuildingTypeFilterTextColor={handleBuildingTypeFilterTextColor}
        /> */}
        <Sidebar
          selectedPart={selectedPart}
          setSelectedPart={setSelectedPart}
          buildings={buildings}
          handleBuildingClick={handleBuildingClick}
          handleBackToList={handleBackToList}
          selectedRoom={selectedRoom}
          handleRoomDetails={handleRoomDetails}
          universityDetails={universityDetails}
        />

        {showDebugTool && selectedPart && (
          <DebugTool
            buildingPositions={buildingPositions}
            adjustPosition={adjustPosition}
            selectedBuildingName={selectedPart}
          />
        )}
      </div>
    </ChakraProvider>
  );
};

export default R3fMap;
