import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

const SharedModal = ({
  isOpen,
  onClose,
  children,
  isCentered = true,
  size,
  height,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isCentered}
      size={size}
    >
      <ModalOverlay />
      <ModalContent height={height}>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SharedModal;
